import styled from 'styled-components'
import * as React from "react"

const FullVideo = styled.div`

  width: 70%;

  @media only screen and (max-width: 700px) {
    width: 100%;
  }

  margin: 0 auto;

  > div {
    position: relative;
    width: calc(100% - 40px);
    padding-bottom: 56.25%;
    margin: 20px 20px 0 20px;

    @media only screen and (max-width: 500px) {
      width: 100%;
      margin: 20px 0 0 0;
    }
  }
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  } 

  span {
    display: inline-block;
    width: calc(100% - 40px);
    border-bottom: 1px dotted #dfcea1;
    margin: 8px 20px 20px;

    @media only screen and (max-width: 500px) {
      width: 100%;
      margin: 8px 0 20px 0;
    }
  } 
`;

// markup
const VideoPlayer = (props) => {
  return (
    <FullVideo>
      <div>
        <iframe
          src={props.src}
          srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${props.src}><img src=https://img.youtube.com/vi/${props.id}/hqdefault.jpg alt='Video '${props.title}'><span>▶</span></a>`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          loading="lazy"
          title={props.title}
        ></iframe>
      </div>
      <span>{props.title}</span>
    </FullVideo>
  )
}

export default VideoPlayer