import * as React from "react"
import Layout from "../../../components/layout"
import "@fontsource/raleway/300.css"
import { Link } from 'gatsby'
import Hero from '../../../components/hero'
import Header from '../../../components/header'
import AdBanner from '../../../components/adbanner'
import { StaticImage } from 'gatsby-plugin-image'
import { ImageRight, ImageFull, ImageLeft } from '../../../components/imageelements'
import { Helmet } from "react-helmet"
import AudioSet from '../../../components/audioset'
import { AdContainer } from '../../../components/adstyling'
import { MainBodySection, HighlightText } from '../../../components/generalstyling'
import VideoPlayer from '../../../components/videoplayer'
import Bibliography from '../../../components/bibliography.js'


const bibliography = [
  {name: 'Opera in America: A Cultural History', writer: 'John Dizikes', url: 'https://amzn.to/3BKfdLM'},
  {name: 'Henry Purcell\'s Dido and Aeneas', writer: 'Ellen T. Harris', url: 'https://amzn.to/2Yi5jmb'},
  {name: 'The Lives of the Great Composers', writer: 'Harold C. Schonberg', url: 'https://amzn.to/2YjtFvS'},
  {name: 'Memoirs of the Life of the late George Frederic Handel', writer: 'John Mainwaring & Ilias Chrissochoidis', url: 'https://amzn.to/3BNhS7H'},
  {name: 'The Complete Annotated Gilbert & Sullivan', writer: 'Ian Bradley', url: 'https://amzn.to/3thD4Q1'},
]


// markup
const EnglishHistoryPage = () => {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Opera in English | The Opera 101</title>
          <html lang="en-us" />
          <link rel="canonical" href="https://theopera101.com/operaabc/history/english" />
          <meta name="description" content="A brief history of opera in English. There's been more written in English than you might expect!" />
        </Helmet>
        <Hero title="Opera in English"/>
        <MainBodySection>

          <HighlightText>The common stereotype of opera rarely involves people singing in English and there's no denying the immense popularity of operas written in German, Italian and French. Yet it would be foolish to discount opera in English. English language opera has a long, if decidedly uneven, history.</HighlightText>

          <Header title="17th Century"/>

          <ImageLeft maxwidth="150px">
            <StaticImage src="../../../images/abcs/englishhistory/erismena.JPG" alt="The Spine of Erismena in English"/>
            <figcaption>The spine of Erismena</figcaption>
          </ImageLeft>

          <p>In the 17th Century, a time when opera was getting going in Italy and France, Britain was undergoing a series of colossal upheavals that led to drama being effectively outlawed. The Puritans under Oliver Cromwell had closed all the theatres in 1642 and plays had been banned. A savvy impresario, William Davenant, faced with being unable to present his works, sidestepped the ban by setting his latest play, <i>The Siege of Rhodes</i>, to music (music being allowed whilst plays were not!). Thus was born, out of dismal circumstances, the first ever opera in English, performed (privately) in 1656. Nothing of the music remains today, and written by 5 different composers as an afterthought to what was intended to be a play, it’s a work more of historic interest than theatrical merit.</p>

          
          <div id="ezoic-pub-ad-placeholder-102"> </div>

          <ImageRight maxwidth="240px">
            <StaticImage src="../../../images/abcs/englishhistory/cromwell.jpg" alt="Oliver Cromwell"/>
            <figcaption>Oliver Cromwell</figcaption>
          </ImageRight>

          <p>With the fall of Cromwell and the restoration of Charles II in 1660, opera in Britain was briefly allowed to develop as more than just a sneaky way to get plays performed. But, it wasn’t really a case of the flood gates opening, in fact it took some twenty years before a true English opera was created. However, after all his time exiled in France, King Charles had developed a taste for the music of the court of Louis XIV. In part because of this, opera did begin to be imported. Francesco Cavalli’s <i>Erismena</i> possibly the first foreign language opera to be translated into English in 1674.</p>

          <p>If French opera was created by Jean-Baptiste Lully by borrowing liberally from Italian opera, then British opera was truly kicked off by Matthew Locke and John Blow who borrowed from Lully and the French. Locke was one of the composers of <i>The Siege of Rhodes</i> and his masque (a popular form of court entertainment involving acting, singing and dancing) <i>Orpheus and Euridice</i> of 1673 made a decisive push towards making music an integral part of the plot (something that wasn’t really true of the traditional masque). </p>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-103"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>

          <p>Blow’s <i>Venus and Adonis</i> followed on from this, premiering around 1683 as a private performance for the King. <i>Venus and Adonis</i> is held as the earliest existing English language opera, a through-composed work though still in some ways a masque in form. This would be Blow’s only contribution to the stage. As with many other British composers of the period, Blow was heavily associated with the church (a hallmark of British music that has continued to this day) and ecclesiastical anthems make up the bulk of his output.</p>

          <p>One of Blow’s students, Henry Purcell, would soon create the crowning achievement of the English Baroque operatic movement in <i>Dido and Aeneas</i>, 1689. Purcell then gave up on the fully-sung opera and wrote semi-operas for the rest of his career. The semi-opera, a decidedly British form, harkens back to Davenant’s quasi-musical creations being spoken plays with musical episodes tacked on. The best known today is Purcell’s <i>The Fairy Queen</i>, 1692, a hacked apart version of Shakespeare’s <i>A Midsummer Night’s Dream</i>, but the earliest work that remains is Louis Grabu’s <i>Albion and Albanius</i>, 1685 (which can also stake its claim to being the earliest “full length” English language opera).</p>

          <VideoPlayer src="https://www.youtube.com/embed/PeB4cpRq16M?start=6&autoplay=1" id="PeB4cpRq16M" title="Dido's Lament from Dido and Aeneas sung by Sarah Connolly"/>

          <p>After Purcell the popularity of opera and semi-opera dwindled in Britain and to be brutally honest that was pretty close to the end of opera in English. Thomas Arne had a moderately successful crack at reviving the form by imitating Italian styles on English texts in the mid 1700s but it didn’t sustain past his own career. These were mostly lightweight comedies but he had considerable success with <i>Artaxerxes</i>, 1762, which now stands as about the only English language opera seria.</p>

          <ImageFull>
            <StaticImage src="../../../images/abcs/englishhistory/Artax_ROH_JohanEngels.jpg" alt="Artaxerxes reimagined at the Royal Opera"/>
            <figcaption>Artaxerxes reimagined at the Royal Opera</figcaption>
          </ImageFull>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-104"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>

          <Header title="18th Century"/>

          <p>Otherwise it was left to George Frederic Handel to dominate the British stages and his operatic works were almost entirely written in Italian. Handel turned later in his life towards the English choral tradition and turned out a huge number of English language oratorios, some of which have since been pinched back to the opera house such as <i>Semele</i>, 1743.</p>

          <p>An interesting outlier in all this is <i>The Beggar’s Opera</i>, 1728, by John Gay and the various accompanying ballad operas.  These racy works had none of the noble, heroic narratives of the mainstream Handellian opera of the time and were instead biting, satirical pieces featuring predominantly lower class characters. These had spoken dialogue and short, punchy, witty songs leading to swifter narratives and many more laughs. They used well known tunes with new lyrics and virtually none have made it to the 21st Century (<i>The Beggar’s Opera</i> being the major exception). The ballad opera also proved short lived in popularity, threatening Handel's popularity for a few years but nearly moribund by the 1750s.</p>

          <Header title="19th Century"/>

          <AudioSet audiosrc="/audio/englishhistory/Garanca_Bohemian.mp3" desc="Elina Garanca sings from The Bohemian Girl"/>

          <p>The outlook for the next 150 years was particularly bleak and with the exception of a few passing fancies, Michael Balfe’s <i>The Bohemian Girl</i>, 1843, and William Wallace’s <i>Maritana</i>, 1845, which are basically Italian operas with English texts, there were few significant works (and if you’ve heard either of those live we’re very impressed...). Plenty of operas were performed but the fashions were for Italian, French and later German works with the end result being that English works just weren’t written.</p>

          <p>By the late 19th Century, W. S. Gilbert and Arthur Sullivan would make great hay parodying the continental operatic conventions in their wonderful Savoy Operas, a sort of Romantic ballad opera. However, with the exception of Sullivan’s brief foray into Grand Opera with <i>Ivanhoe</i>, 1891, anyone looking for a great 19th Century English language opera is going to be disappointed.</p>

          <VideoPlayer src="https://www.youtube.com/embed/665qUsk56V4?start=296&autoplay=1" id="665qUsk56V4" title="Eric Idle as The Lord High Executioner of Titipu in Gilbert and Sullivan's The Mikado at the ENO"/>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-105"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>

          <Header title="20th Century"/>

          <ImageRight>
            <StaticImage src="../../../images/abcs/englishhistory/ENO_Riders.jpg" alt="Rider's to the Sea, ENO 2008"/>
            <figcaption>Rider's to the Sea, ENO 2008</figcaption>
          </ImageRight>

          <p>Things began to change quite dramatically in the 20th Century. Britain started to produce operatic composers and what is more, America did too! Against a background of decline on the global opera scene, neither German nor Italian opera ever quite recovered from the end of the Romantic period, the English language resurgence was remarkable.</p>

          <p>In Britain, Gustav Holst and Ralph Vaughan Williams were first out the gate producing a series of operas, including <i>The Perfect Fool</i>, 1923, by the former and <i>Riders to the Sea</i>, 1937, by the latter. The real game changer however was Benjamin Britten, whose early masterpiece <i>Peter Grimes</i>, 1945, fast entered the repertory and hasn’t left since.</p>

          <AudioSet audiosrc="/audio/englishhistory/Grimes_Dawn.mp3" desc="The beginning of Dawn from Peter Grimes"/>

          <p>Today Benjamin Britten is, by some margin, the most performed opera composer born after 1900: his output from the 1940s to the 1970s dominates contemporary opera. At a time when classical music had become what many might call “difficult” (scarcely fair but atonalism and serialism aren’t the byword in beauty), Britten produced operas whose accessibility has seldom been matched.</p>

          <p>Perhaps we can say then, that the floodgates of opera in English had finally opened: Michael Tippett, Harrison Birtwistle and Peter Maxwell Davis amongst others producing dozens of successful works. If there is a German titan of 20th Century opera it is Hans Werner Henze, and even he wrote several English language works including <i>Elegy for Young Lovers</i>, 1961, the libretto by poet W. H. Auden. The interesting outlier again relates to Ballad Opera, this time going the other way. Bertolt Brecht and Kurt Weill producing <i>Die Dreigroschenoper</i>, 1928, (The Threepenny Opera) a German take on Gay's <i>The Beggar's Opera</i>.</p>

          <ImageFull>
            <StaticImage src="../../../images/abcs/englishhistory/NYCItalianOpera.jpg" alt="The first New York Italian Opera House"/>
            <figcaption>The first New York Italian Opera House</figcaption>
          </ImageFull>

          <p>The story of opera in the USA, unsurprisingly, starts later than in Europe. A major early driving force was Lorenzo Da Ponte, the librettist of several of Mozart’s masterpieces (<Link to="/operas/giovanni">Don Giovanni</Link>, <i>Le Nozze di Figaro</i> and <i>Cosi fan tutte</i>), who moved to New York in 1805. He helped, along with the vast numbers of European immigrants, to create a vogue for European opera and founded the very first American opera company in 1833 (though it was to prove short lived). Genuine American operas were few and far between, and it wasn’t until the emergence of the great Broadway musical that American opera really got going, Gershwin’s <i>Porgy and Bess</i> finding a ready home in opera houses the world over.</p>

        </MainBodySection>

        <div id="ezoic-pub-ad-placeholder-106"> </div>

        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection>

          <ImageRight>
            <StaticImage src="../../../images/abcs/englishhistory/Hogarth_Rake.jpg" alt="The 3rd Painting in Hogarth's series A Rake's Progress"/>
            <figcaption>The 3rd Painting in Hogarth's series A Rake's Progress</figcaption>
          </ImageRight>

          <p>Another factor in the rise of American music was the influx of opera composers fleeing the Third Reich. Not all would continue to write opera, Wolfgang Korngold writing incredible film scores instead (his scores won two Oscars while his German language operas languished in obscurity), and Béla Bartók never gained the recognition in America required to produce new operas. However, Igor Stravinsky contributed <i>The Rake’s Progress</i>, 1951, a masterpiece of English language opera with a libretto by Auden and Chester Kallman.</p>

          <p>Possibly the most prolific American opera composer was Gian Carlo Menotti who wrote nearly 20 operas. His most famous work, <i>Amahl and the Night Visitors</i>, 1951, was  written for the first time for television rather than the stage (in a version <a href="http://amzn.to/2gpwEaO">still available today</a>). The wave of English language opera would continue with the next generation of American operatic composers, many rooted in the minimalism movement. Philip Glass’s <i>Einstein on the Beach</i> crashed onto the scene in 1971 and John Adams <i>Nixon in China</i> in 1987.</p>

          <Header title="Contemporary Opera"/>

          <VideoPlayer src="https://www.youtube.com/embed/mmfeIyjQxvI?autoplay=1" id="mmfeIyjQxvI" title="Gerald Finley singing 'Batter my Heart' from Doctor Atomic, John Adam's 2005 opera"/>

          <HighlightText>Today opera in English is thriving with Thomas Adès, George Benjamin, Mark-Anthony Turnage, Judith Weir, Jake Heggie, Nico Muhly, Missy Mazzoli, Du Yun and many more, including even pop artists like Damon Albarn and Rufus Wainwright, contributing to a lively contemporary operatic scene. Internationally the volume of new opera has seldom been lower, but at least in English, opera has never been so strong.</HighlightText>

          <Header title="Bibliography"/>

          <Bibliography content={bibliography}/>

        </MainBodySection>
      </Layout>
  )
}

export default EnglishHistoryPage